<template>
    <v-container fluid>
        <v-row style="padding-bottom: 20px;">
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Work Orders - Aging</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'workOrderForm', params: { id: 0 } }"
                        >
                            Export PDF
                        </v-btn>
                    </template>
                    <span>Export Report to PDF</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="3"
                md="6"
                sm="12"
            >
                <label>Category</label>
                <v-combobox
                    v-model="filter.categorySelected"
                    :items="listCategory"
                    item-text="description"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="3"
                md="6"
                sm="12"
            >
                <label>Type</label>
                <v-combobox
                    v-model="filter.typeSelected"
                    :items="listType"
                    item-text="description"
                    item-value="value"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters(); filter.responsibleSelected = null;"
                ></v-combobox>
            </v-col>
            <v-col 
                v-if="showExternalResponsible == false"
                cols="12"
                lg="3"
                md="12"
                sm="12"
            >
                <label>Responsible</label>
                <v-combobox
                    v-model="filter.responsibleSelected"
                    :items="listUser"
                    item-text="name"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                v-else
                cols="12"
                lg="3"
                md="12"
                sm="12"
            >
                <label>Responsible</label>
                <v-combobox
                    v-model="filter.responsibleSelected"
                    :items="listContractor"
                    item-text="name"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="3"
                md="6"
                sm="12"
            >
                <label>Status</label>
                <v-combobox
                    v-model="filter.statusSelected"
                    :items="listStatus" 
                    item-text="statusDescription"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="listWorkOrderFiltered"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.targetDateFormatted="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.targetDateFormatted }}
                        </label>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'workOrderForm'"
                            :showButtons="{
                                edit: item.status === 0 ? true : false,
                                delete: false,
                                cancel2: item.status === 0 ? true : false,
                                markStarted: item.status === 0 ? true : false,
                                markDoneUnDone: item.status === 1 ? true : false,
                                markFailed: item.status === 1 ? true : false,
                                hold: item.status == 0 || item.status == 1 || item.status == 3 ? true : false,
                                resume: item.status === 5 ? true : false,
                                download: item.status !== 4 ? true : false
                            }"
                            @confirmCancel2="confirmCancel"
                            @confirmMarkAsStarted="confirmMarkAsStarted"
                            @confirmMarkAsFailed="confirmMarkAsFailed"
                            @confirmDoneUnDone="confirmDoneUnDone"
                            @confirmHold="confirmHold"
                            @confirmResume="confirmResume"
                            @confirmDownload="confirmDownload"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList,
            ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            id: 0,
            statusId: 0,

            loading: false,

            filter: {
                fastSearch: '',
                categorySelected: null,
                typeSelected: null,
                responsibleSelected: null,
                statusSelected: null,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Category", value: "categoryWorkOrderDescription", sortable: true },
                { text: "Less Than 7 Days", value: "id", sortable: true },
                { text: "Between 8 and 30 Days", value: "id", sortable: true },
                { text: "Greater Than 30 Days", value: "id", sortable: true }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listWorkOrder: [],
            listWorkOrderFiltered: [],
            listCategory: [],
            listType: [],
            listUser: [],
            listContractor: [],
            listStatus: [],

            showDialogDate: false,
            typeSchedule: '',
            description: '',
        }),


        computed: {

            showExternalResponsible() {
                return this.filter.categorySelected != null && 
                       this.filter.categorySelected != undefined && 
                       this.filter.typeSelected != null &&
                       this.filter.typeSelected != undefined &&
                       this.filter.typeSelected.id == 2
            },
        },

        methods: {
            
            async getRegisters() {

                this.listWorkOrder = await this.$store.dispatch("workOrderModule/List");
                this.listWorkOrderFiltered = [...this.listWorkOrder];
                
                this.listStatus = await this.$store.dispatch("workOrderModule/ListStatus");
                this.listCategory = await this.$store.dispatch("workOrderModule/ListCategory");
                this.listType = await this.$store.dispatch("workOrderModule/ListType");
                this.listUser = await this.$store.dispatch("userModule/List");
                this.listContractor = await this.$store.dispatch("contractorModule/List");
                this.filterRegisters();
            },

            async filterRegisters() {

                let idCategory = 0;
                let idType = 0;
                let idResponsible = 0;
                let idStatus = 0;
                let listFiltered = [...this.listWorkOrder];

                if (this.filter.categorySelected != null && this.filter.categorySelected != undefined) {
                    idCategory = this.filter.categorySelected.id;
                    listFiltered = listFiltered.filter(wor => wor.categoryWorkOrder == idCategory);
                }

                if (this.filter.typeSelected != null && this.filter.typeSelected != undefined) {
                    idType = this.filter.typeSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.typeWorkOrder == idType);
                }

                if (this.filter.responsibleSelected != null && this.filter.responsibleSelected != undefined) {
                    idResponsible = this.filter.responsibleSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.idInstaller == idResponsible);
                }

                if (this.filter.statusSelected != null && this.filter.statusSelected != undefined) {
                    idStatus = this.filter.statusSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.status == idStatus);
                }

                if (this.filter.targetDateStart != null && this.filter.targetDateStart != undefined && 
                    this.filter.targetDateEnd != null && this.filter.targetDateEnd != undefined) {
                    listFiltered = listFiltered.filter(wor => (wor.targetDate >= this.filter.targetDateStart && wor.targetDate <= this.filter.targetDateEnd) || (wor.targetDate == null || wor.targetDate == undefined));
                }

                this.listWorkOrderFiltered = listFiltered;
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }
</style>